<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGAME_CWFormData && Object.keys(vmGAME_CWFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationword">
      {{cvWordLabel}}</label
    >
    <input
      v-model="vmGAME_CWFormData.word"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationdescription">
      {{cvDescriptionLabel}}</label
    >
    <input
      v-model="vmGAME_CWFormData.description"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="CrossWordEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { CrossWords } from "../../../FackApi/api/game_cw"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "GameCwEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGAME_CWObj: {
      type: Object,
      default: function () {
        return {
          "word": "",
          "description": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit Game Cw",
      cvCardSubHeader: "Edit Game Cw ",
      cvSubmitBtn: "Edit",
      cvWordLabel: "word",
      cvDescriptionLabel: "description",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GAME_CW Edit Response",
      vmGAME_CWFormData: {}
    }
  },
  mounted () {
    this.CrossWordView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGAME_CWFormData) {
          if (!this.vmGAME_CWFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * CrossWordView
     */
    async CrossWordView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGAME_CWFormData = this.propGAME_CWObj
        }
        else {
          let gameCwId = this.$route.params.game_cw_id
          let gameCwViewResp = await CrossWords.CrossWordView(this, gameCwId)

          if (gameCwViewResp && gameCwViewResp.resp_status) {
            this.vmGAME_CWFormData = gameCwViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at CrossWordView() and Exception:", err.message)
      }
    },
    /**
     * CrossWordEdit
     */
    async CrossWordEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gameCwAddResp = await CrossWords.CrossWordEdit(this, this.vmGAME_CWFormData)
        await ApiResponse.responseMessageDisplay(this, gameCwAddResp)

        if (gameCwAddResp && !gameCwAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGameCwEditModal", this.vmGAME_CWFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at CrossWordEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
