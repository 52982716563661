/*eslint-disable */
// import GAME_CWModel from '../../Model/GAME_CW'
import request from '../../Utils/curl'

let CrossWords = {
  /**
   * CrossWordList
   */
  async CrossWordList (context) {
    try{
      let postData = new FormData();
  
      return await request.curl(context, "game_cw_list", postData)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at CrossWordList() and Exception:',err.message)
    }
  },

  /**
   * CrossWordView
   */
  async CrossWordView (context, gameCwId) {
    try {      
      let postData = new FormData();
      postData.append('game_cw_id', gameCwId);
      return await request.curl(context, "game_cw_view", postData)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at CrossWordView() and Exception:",err.message)
    }    
  },

  /**
   * CrossWordAdd
   */
  async CrossWordAdd (context, gameCwObj) {
    try{
      let postData = new FormData();
    
      for (let key in gameCwObj) {
        postData.append(key, gameCwObj[key]);
      }

      return await request.curl(context, "game_cw_add", postData)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at CrossWordAdd() and Exception:',err.message)
    }
  },

  /**
   * CrossWordEdit
   */
  async CrossWordEdit (context, gameCwObj) {
    try{
      let postData = new FormData();
    
      for (let key in gameCwObj) {
        postData.append(key, gameCwObj[key]);
      }

      return await request.curl(context, "game_cw_edit", postData)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at CrossWordEdit() and Exception:',err.message)
    }
  },

  /**
   * CrossWordDelete
   */
  async CrossWordDelete (context, gameCwId) {
    try{
      let postData = new FormData();
      
      postData.append('game_cw_id', gameCwId);

      return await request.curl(context, "game_cw_delete", postData)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at CrossWordDelete() and Exception:',err.message)
    }
  }
}

export {
  CrossWords
}
